import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import { IntercomService } from '@rma/generic/util/tracking/intercom';
import { FooterContactDetails } from '../data-access/footer.facade';
import { SocialLink } from '../domain/footer-links.model';

@Component({
  selector: 'rma-footer-contact',
  imports: [NgOptimizedImage, TranslocoModule, RmaIconComponent, MatButtonModule],
  templateUrl: './footer-contact.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-col gap-4',
  },
})
export class FooterContactComponent {
  @Input({ required: true })
  public details: FooterContactDetails | null = null;

  @Input({ required: true })
  public socialLinks: SocialLink[] | null = null;

  private readonly intercomService = inject(IntercomService);

  public openIntercom(): void {
    this.intercomService.showMessenger();
  }
}
