import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { FooterLink } from '../domain/footer-links.model';
import { FooterLinkComponent } from '../ui-footer-link/footer-link.component';

@Component({
  selector: 'rma-legal-links',
  imports: [FooterLinkComponent, TranslocoModule],
  templateUrl: './legal-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-col md:flex-row gap-4 ml-4 md:ml-14',
  },
})
export class LegalLinksComponent {
  @Input({ required: true })
  public links: FooterLink[] | null = null;

  protected readonly currentYear: number = new Date().getFullYear();
}
