import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FooterFacade } from '../data-access/footer.facade';
import { FooterContactComponent } from '../ui-footer-contact/footer-contact.component';
import { LegalLinksComponent } from '../ui-legal-links/legal-links.component';
import { QuickSearchComponent } from '../ui-quick-search/quick-search.component';
import { SiteLinksComponent } from '../ui-site-links/site-links.component';

@Component({
  selector: 'rma-footer',
  imports: [FooterContactComponent, SiteLinksComponent, LegalLinksComponent, QuickSearchComponent, AsyncPipe],
  templateUrl: './footer.component.html',
  host: {
    class: 'flex flex-col gap-4 pb-4 px-4 2xl:px-0',
  },
})
export class FooterComponent {
  private readonly footerService = inject(FooterFacade);

  protected readonly communitySiteLinks$ = this.footerService.getCommunitySiteLinks();
  protected readonly contactDetails$ = this.footerService.getContactDetails();
  protected readonly hasFinance$ = this.footerService.hasFinance$;
  protected readonly hasLeasing$ = this.footerService.hasLeasing$;
  protected readonly legalLinks$ = this.footerService.getLegalLinks();
  protected readonly siteLinkGroups$ = this.footerService.getSiteLinks();
  protected readonly sitemapLinks$ = this.footerService.locationLinks$;
  protected readonly socialLinks = this.footerService.socialLinks;
}
