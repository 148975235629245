import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { SiteLinkGroup } from '../domain/site-link-group.model';
import { FOOTER_TRANSLATION_SCOPE } from '../domain/translation-scopes';
import { FooterLinkComponent } from '../ui-footer-link/footer-link.component';

@Component({
  selector: 'rma-footer-site-links',
  imports: [FooterLinkComponent, TranslocoModule],
  templateUrl: './site-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope(FOOTER_TRANSLATION_SCOPE)],
})
export class SiteLinksComponent {
  @Input({ required: true })
  public linkGroups!: SiteLinkGroup[] | null;

  protected readonly scope = `${FOOTER_TRANSLATION_SCOPE}.siteLinks`;
}
