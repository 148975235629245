import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@jsverse/transloco';
import { RmaIconComponent } from '@rma/generic/ui/icon';

@Component({
  selector: 'rma-site-header-search',
  imports: [RmaIconComponent, RouterLink, TranslocoDirective],
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteHeaderSearchComponent {
  public readonly scope = input.required<string>();
}
