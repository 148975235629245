import { EnvironmentProviders, ErrorHandler, makeEnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { TrackJsService } from './feat-track-js/track-js.service';
import { TrackJsErrorHandler } from './util-track-js/track-js.error-handler';

function initialiseTrackJS(trackJsService: TrackJsService) {
  return () => trackJsService.initialise();
}

export function provideRmaTrackJs(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideAppInitializer(() => {
      const initializerFn = initialiseTrackJS(inject(TrackJsService));
      return initializerFn();
    }),
    { provide: ErrorHandler, useClass: TrackJsErrorHandler },
  ]);
}
