import { OverlayRef } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import {
  afterNextRender,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterModule,
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FacebookPixelComponent } from '@rma/generic/ui/facebook-pixel';
import type { RmaOverlayService } from '@rma/generic/ui/overlay';
import { ViewportHeightService } from '@rma/generic/util/device-size';
import { LazyInject } from '@rma/generic/util/lazy-inject';
import { rmaShareReplay } from '@rma/generic/util/operators/combined';
import { FooterComponent } from '@rma/site/private/orc-footer';
import { SiteHeaderComponent } from '@rma/site/private/orc-header';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { LazyLoadThirdPartyScriptsService } from '../util-lazy-load-scripts/lazy-load-scripts';

@UntilDestroy()
@Component({
  selector: 'rma-root',
  templateUrl: './rma-layout.component.html',
  styleUrls: ['./rma-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LazyLoadThirdPartyScriptsService],
  imports: [AsyncPipe, FooterComponent, MatProgressSpinnerModule, RouterModule, SiteHeaderComponent, FacebookPixelComponent],
})
export class RmaLayoutComponent implements OnInit {
  public isLoading: boolean;
  public showSideNav: boolean;

  protected basicHeader$: Observable<boolean>;
  protected fullWidthHeader$: Observable<boolean>;
  protected showFooter$: Observable<boolean>;
  protected showHeader$: Observable<boolean>;

  private sideNavRef?: OverlayRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly lazyInjector: LazyInject,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly zone: NgZone,
    _viewportHeightService: ViewportHeightService,
    lazyLoad3rdPartyScripts: LazyLoadThirdPartyScriptsService,
  ) {
    afterNextRender(() => lazyLoad3rdPartyScripts.initialize());
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.zone.runOutsideAngular(() => {
      const routeData$ = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        catchError(() => of({ hideFooter: false, hideHeader: false, basicHeader: false, fullWidthHeader: false })),
        rmaShareReplay(),
        untilDestroyed(this),
      );

      this.showFooter$ = routeData$.pipe(map(({ hideFooter }) => !hideFooter));
      this.showHeader$ = routeData$.pipe(map(({ hideHeader }) => !hideHeader));
      this.basicHeader$ = routeData$.pipe(map(({ basicHeader }) => !!basicHeader));
      this.fullWidthHeader$ = routeData$.pipe(map(({ fullWidthHeader }) => !!fullWidthHeader));

      routeData$.pipe(untilDestroyed(this)).subscribe({
        next: () => this.hideSideNav(),
        error: (err) => console.log(err),
      });

      this.router.events.pipe(untilDestroyed(this)).subscribe((e: Event) => this.setLoading(e));
    });
  }

  public toggleSideNav() {
    this.showSideNav = !this.showSideNav;
    this.getSideNavComponent(this.showSideNav);
  }

  private setLoading(event: Event): void {
    if (event instanceof NavigationStart) {
      this.isLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.isLoading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isLoading = false;
    }
    if (event instanceof NavigationError) {
      this.isLoading = false;
    }
  }

  private async getSideNavComponent(show: boolean) {
    if (show) {
      const overlayService = await this.lazyInjector.get<RmaOverlayService>(() =>
        import('@rma/generic/ui/overlay').then((m) => m.RmaOverlayService),
      );

      const { SidenavComponent } = await import('@rma/site/private/ui-mobile-side-nav');

      const { overlayRef } = overlayService.open(this.vcr, SidenavComponent, {
        hasBackdrop: true,
        backdropClass: 'mobile-side-nav',
        disposeOnNavigation: true,
        scrollStrategy: overlayService.overlay.scrollStrategies.block(),
        positionStrategy: overlayService.overlay.position().global().right().top('64px'),
      });

      this.sideNavRef = overlayRef;

      this.sideNavRef?.backdropClick().subscribe(() => this.hideSideNav());
    } else {
      this.sideNavRef?.detach();
    }

    this.cdr.markForCheck();
  }

  private hideSideNav() {
    this.sideNavRef?.detach();
    this.showSideNav = false;
  }
}
