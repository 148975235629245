import { Routes } from '@angular/router';

export const betaRoutes: Routes = [
  {
    path: 'beta',
    children: [
      {
        path: 'home',
        loadComponent: () => import('@rma/site/home-beta'),
      },
      {
        path: 'nlp-search-hack',
        loadComponent: () => import('@rma/search/nlp-search'),
      },
    ],
  },
];
