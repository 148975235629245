import { EnvironmentProviders, NgZone, makeEnvironmentProviders, inject, provideAppInitializer } from '@angular/core';
import { PlatformService } from '@rma/generic/util/environment';
import { Angulartics2GoogleAnalytics, Angulartics2GoogleGlobalSiteTag } from 'angulartics2';

function initialiseGoogleTracking(
  platformService: PlatformService,
  angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  angulartics: Angulartics2GoogleGlobalSiteTag,
  zone: NgZone,
): () => void {
  return () => {
    if (platformService.isTrackable) {
      zone.runOutsideAngular(() => {
        // GA Universal. Remove after July 1st 2023
        angulartics2GoogleAnalytics.startTracking();
        // GA 4 tracking. Can we implement this ourselves?
        angulartics.startTracking();
      });
    }
  };
}

export function provideRmaGoogleTracking(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideAppInitializer(() => {
      const initializerFn = initialiseGoogleTracking(
        inject(PlatformService),
        inject(Angulartics2GoogleAnalytics),
        inject(Angulartics2GoogleGlobalSiteTag),
        inject(NgZone),
      );
      return initializerFn();
    }),
  ]);
}
